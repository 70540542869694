import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import useSiteMetadata from './SiteMetadata'
import AuthorInfo from './author-info'

const BlogContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  a {
    color: black;
  }
  .main img{
    margin-right: 1rem;
  }
  a:hover h4 {
    color: var(--tbwa-yellow);
  }
  article.main{
    width: 100%;

  }
  .article{
    margin-bottom:1rem;
    width: 380px;
    height: 450px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.25);
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
  h4 {
    margin-top: 0.2rem;
  }
  ul li {
    list-style: none;
    margin-right: 1rem;
  }
  .grid {
    display: flex;
    justify-content:center;
    margin: 2rem 0;
  }
  .grid img {
    margin-right: 2rem;
  }
  .tag{
    font-size:12px;
    font-weight: bold;
  }
  @media (max-width: 800px) {
    .grid {
      flex-wrap: wrap;
    }
    .article {
      width: 100%;
      height: 500px;
    }
  }
  .posts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
  }
`

const BlogRoll = props => {
  const { data } = props
  const { title, description } = useSiteMetadata()

  const { edges: posts } = data.allMarkdownRemark
  const [firstPost, ...rest] = posts;

  const formatTitle = (str)=>{
    if(str.length>70){
      return `${str.substr(0,80)}...`
    }
    return str;
  }

  return (
    <BlogContainer>
      <div>
        <Link
          className="title has-text-primary is-size-4"
          to={firstPost.node.fields.slug}
        >
          <article className="main">
            <Img
              fluid={firstPost.node.frontmatter.Image.childImageSharp.fluid}
            />
            <div className="grid">
              <AuthorInfo author={firstPost.node.frontmatter.author} />
              <div>
                <h4>{firstPost.node.frontmatter.title}</h4>
                <p> Temps de lecture: {Math.floor(firstPost.node.fields.readingTime.minutes)} minutes</p>
                <span className="subtitle is-size-5 is-block">
                  {firstPost.node.frontmatter.date}
                </span>
                <p>{firstPost.node.excerpt}</p>
              </div>
            </div>
          </article>
        </Link>
      </div>
      <div className="posts-container">
        {rest &&
          rest.map(({ node: post }) => (
            <Link
            className='article'
              to={post.fields.slug}
            >
              <article key={post.id}>
                <Img
                  style={{ width: '100%' }}
                  fixed={post.frontmatter.Image.childImageSharp.fixed}
                />
                <div style={{padding:'1rem'}}>
                <h4>{formatTitle(post.frontmatter.title)}</h4>
                {post.frontmatter.tags && post.frontmatter.tags.length ? (
                  <ul className="taglist">
                    {post.frontmatter.tags.map(tag => (
                      <li className="tag" key={tag + `tag`}>{tag}</li>
                    ))}
                  </ul>
                ) : null}
                <span className="subtitle is-size-5 is-block">
                  {post.frontmatter.date}
                </span>
                </div>
              </article>
            </Link>
          ))}
      </div>
    </BlogContainer>
  )
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 300)
              id
              fields {
                slug
                readingTime {
                  minutes
                }
              }
              frontmatter {
                title
                tags
                author{
                  detail
                  image{
                    childImageSharp {
                      fixed(width: 125, height: 125) {
                        ...GatsbyImageSharpFixed
                      }
                    }
                  }
                }
                Image {
                  childImageSharp {
                    id
                    fluid(maxWidth: 800, maxHeight: 350) {
                      ...GatsbyImageSharpFluid
                    }
                    fixed(width: 350, height: 250) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                templateKey
                date(formatString: "DD MMMM YYYY",locale:"fr")
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
)
