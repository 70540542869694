import React from "react";
import Helmet from "react-helmet";
import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";
import { HeroSection, UnderLine } from "../../components/global-style";
import BlogImage from "../../img/images/blog.jpg";
import BackSlash from "../../img/svg/backslash.svg";
import styled from "styled-components";
import addToMailchimp from "gatsby-plugin-mailchimp";

const Newsletter = styled.div`
  form {
    display: flex;
    flex-wrap: wrap;
    p {
      margin-right: 1rem;
    }
    & > div {
      display: flex;
      margin-right: 1rem;
      input {
        max-width: 300px;
      }
    }
    button {
      background: var(--tbwa-yellow);
      margin-left: 5px;
      img {
        width: 20px;
      }
    }
  }
`;
const HeroBlog = styled.div`
  max-width: 600px;
  margin: 0 auto;
  .privacy {
    font-size: 12px;
    color: var(--tbwa-dark-gray);
  }
`;
export default class BlogIndexPage extends React.Component {
  state = {
    name: null,
    email: null,
    message: null
  };
  _handleChange = e => {
    console.log({
      [`${e.target.name}`]: e.target.value
    });
    this.setState({
      [`${e.target.name}`]: e.target.value
    });
  };

  _handleSubmit = e => {
    const { email } = this.state;
    e.preventDefault();
    addToMailchimp(this.state.email, this.state)
      .then(({ msg, result }) => {

        if (result !== "success") {
          throw msg;
        }
        this.setState({
          message: msg
        });
      })
      .catch(err => {
        console.log("err", err);
        this.setState({
          message: err
        });
        alert(err);
      });
    // I recommend setting `result` to React state
    // but you can do whatever you want
  };
  render() {
    const {message} =this.state;
    return (
      <Layout>
        <Helmet>
          <html lang="fr" />
          <title>Blog-TBWA \ DJAZ</title>
          <meta
            name="description"
            content="Bienvenu sur le blog de TBWA\ DJAZ. Retrouvez ici l'actualité de la communication en Algérie et ailleurs, les conseils utiles de nos experts, nos coup de coeurs et nos coups de gueule."
          />
          <meta property="og:type" content="business.business" />
          <meta property="og:title" content="TBWA\ DJAZ" />
          <meta property="og:url" content="/http://tbwadjaz.com" />
        </Helmet>
        <HeroSection bgImage={BlogImage}>
          <HeroBlog>
            <h1>Blog</h1>
            <p>
              Bienvenu sur le blog de TBWA\ DJAZ. Retrouvez ici l'actualité de
              la communication en Algérie et ailleurs, les conseils utiles de
              nos experts, nos coup de coeurs et nos coups de gueule.
            </p>
            <UnderLine />
          </HeroBlog>
        </HeroSection>
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
